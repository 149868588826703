
const setToken = (access_token) => {
  // alert('this run ')
  localStorage.setItem("access_token", access_token);
  const isAdmin = JSON.parse(atob(access_token.split('.')[1])).role_id
  // console.log(isAdmin, 'isAdmin');
  localStorage.setItem("isAdmin", isAdmin > 2 ? false : true)
  return true;
};

const getToken = () => {
  const token = localStorage.getItem("access_token");
  if (token) {
    return token;
  } else {
    return false;
  }
};

// const setUername = (username) => {
//     localStorage.setItem("username", username);
//     console.log("Token is ");
//     return true;
//   };
//   const getUsername = () => {
//     var username = localStorage.getItem("username");
//     if (username) {
//       return username;
//     } else {
//       return false;
//     }
//   };




module.exports = {
  setToken,
  getToken,


};
