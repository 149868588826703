import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "rc-pagination/assets/index.css";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import OzontelChatbot from "./views/ozontel-chat/OzontelChatbot";

//React.icons = icons;

ReactDOM.render(
  <Provider store={store}>
    <App />
    <OzontelChatbot/>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
