import React, { useState } from 'react'
import { getToken } from '../../utils/Auth/token';
import jwt_decode from "jwt-decode";
const isAuthenticated = getToken();
var user = isAuthenticated && jwt_decode(isAuthenticated);
const OzontelChatbot = () => {
  let pathmatch = window.location.pathname?.replace("/","")?.split("/")?.[0];
  const [isShow, setIsShow] = useState(false);
  return (
    <> 
      {(user && user?.id && (user?.role_id != 2 && user?.role_id != 1) && pathmatch != "view-client") &&
        <>
          <div id="ozontelchatbot" style={{ display: isShow ? 'flex' : 'none', zIndex: "9999", position: "fixed", bottom: "0px", right: "10px", height: "100%", padding: "10px 0px", flexDirection: "column", justifyContent: "center", alignItems: "end", border: "1px solid #ddd", width: '400px', background: '#fff' }}>
            <button title="Close" className='btn btn-warning px-3' onClick={() => setIsShow(false)}><i className='fa fa-minus' /></button>
            <iframe allow="camera; microphone"  title="Ozonetel-Chat" style={{ height: "100%", border: 0, width: '100%' }} src={`https://in-ccaas.ozonetel.com/toolbar_widget/index.html?action=formLogin&customer=admissify_hh&agentid=${user?.agent_id}&phoneNumber=${user?.ozonetel_agent_phone_number}&pin=${user?.ozonetel_agent_pin}&width=100#login`} />
          </div>
          <button className='btn bg-white shadow p-2 border-blue' onClick={() => setIsShow(true)} style={{ position: "fixed", bottom: "10px", right: "10px", borderRadius: "100%" }}><img src="/avatars/chat-conversation.svg" width={32} height="auto"/></button>
        </>
      }
    </>
  )
}

export default OzontelChatbot